html {
  margin-left: calc(100vw - 100%);
}

.App {
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
  transition: 500ms;
}

@media (prefers-color-scheme: dark) {
  a {
    text-decoration: none;
    color: white;
    transition: 500ms;
  }
}

.App-logo {
  height: 40vmin;
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 1.5em;
  font-weight: normal;
}

h3 {
  font-weight: 600;
}

h4 {
  opacity: 0.6;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 6px;
}

@media(max-width: 800px) {
  h1 {
    font-size: 2.5em;
  }
}

p {
  line-height: 150%;
}

li {
  line-height: 170%;
}


blockquote {
  border-left: 5px solid #858585;
  padding-left: 30px;
  opacity: 0.7;
}

img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.squircle {
  width: 52.1em;
  height: 52.1em;
  position: relative;
  display: inline-block;
  margin:1em auto;
  vertical-align: middle;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 50% 0;
  &>* {
    position: absolute;
    width: 100%;
    display: inline-block;
    height: 100%;
    background: 50% 50% no-repeat;
    z-index:5;
  }
  &:before,
  &:after,
  >*:before,
  >*:after{
    position: absolute;
    background: #00aeef;
  }
  &:before{
    top: 0;
    left: 4em;
    border-top-left-radius: 20em 10em;
    width: 50%;
    bottom: 0;
    border-bottom-left-radius: 20em 10em;
    content: "";  
  }
  &:before {
    top: 0;
    left: 4em;
    border-top-left-radius: 80% 10em;
    width: 50%;
    bottom: 0;
    border-bottom-left-radius: 80% 10em;
    content: "";
  }
  &:after {
    top: 0;
    bottom: 0;
    right: 4em;
    border-top-right-radius: 80% 20%;
    border-bottom-right-radius: 80% 20%;
    width: 25em;
    content: "";
  }
  >*:before {
    top: 4em;
    bottom: 4em;
    border-top-left-radius: 100% 50%;
    border-bottom-left-radius: 100% 50%;
    width: 10em;
    content: "";
  }
  >*:after {
    top: 4em;
    bottom: 4em;
    right: 0;
    border-top-right-radius: 100% 50%;
    border-bottom-right-radius: 100% 50%;
    width: 10em;
    content: "";
    z-index:4;
  }
}
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } 
.embed-container-thin { position: relative; padding-bottom: 100%; height: 0; overflow: hidden; max-width: 100%; } 

.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.embed-container-thin iframe, .embed-container-thin object, .embed-container-thin embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


